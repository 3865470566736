/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/auth/AuthService';
import AuthCallback from '@/views/AuthCallback.vue';
import Error from '@/views/Error.vue';
import userCreateSettings from './formModels/users/userCreate';
import userEditSettings from './formModels/users/userEdit';
import merchantCreateSettings from './formModels/merchants/merchantCreate';
import merchantEditSettings from './formModels/merchants/merchantEdit';

const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home');
const Landing = () => import(/* webpackChunkName: "Landing" */ '@/views/Landing');
const Configuration = () => import(/* webpackChunkName: "Configuration" */ '@/views/Configuration');
const EmptySubView = () => import(/* webpackChunkName: "EmptySubView" */ '@/components/EmptySubView');
const MerchantList = () => import(/* webpackChunkName: "MerchantList" */ '@/views/merchants/MerchantListView.vue');
const UserList = () => import(/* webpackChunkName: "UserList" */ '@/views/users/UserListView.vue');

const AuditRequestList = () => import(/* webpackChunkName: "AuditRequestList" */ '@/views/auditRequests/AuditRequestListView.vue');
const AuditRequestOverview = () => import(/* webpackChunkName: "AuditRequestOverview" */ '@/views/auditRequests/Overview.vue');

const GenericForm = () => import(/* webpackChunkName: "GenericForm" */ '@/components/GenericForm.vue');
const PublicKeyCreate = () => import(/* webpackChunkName: "PublicKey" */ '@/components/keys/PublicKey.vue');
const GenerateKeys = () => import(/* webpackChunkName: "GenerateKeys" */ '@/components/keys/GenerateKeys.vue');
const Form = () => import(/* webpackChunkName: "Form" */ '@/components/formBuilder/Form.vue');

const Integrations = () => import(/* webpackChunkName: "Integrations" */ '@/views/integrations/IntegrationListView.vue');
const CreateIntegration = () => import(/* webpackChunkName: "CreateIntegration" */ '@/views/integrations/CreateIntegration.vue');

const PaymentPlanIndex = () => import(/* webpackChunkName: "PaymentPlan" */ '@/views/paymentPlan/Index.vue');
const PaymentPlanOverview = () => import(/* webpackChunkName: "PaymentPlan" */ '@/views/paymentPlan/Overview.vue');
const PaymentPlanHistory = () => import(/* webpackChunkName: "PaymentPlanHistory" */ '@/views/paymentPlan/History.vue');
const AdaptedRequest = () => import(/* webpackChunkName: "AdaptedRequest" */ '@/views/paymentPlan/AdaptedRequest.vue');


Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/error',
      name: 'error',
      component: Error,
      props: true,
      meta: {
        title: 'Error',
      },
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        allowAnonymous: true,
        title: 'Please select a tenant',
      },
      props: true,
    },
    {
      path: '/callback',
      name: 'Callback',
      component: AuthCallback,
      meta: { allowAnonymous: true },
    },
    {
      path: '/:tenant',
      component: EmptySubView,
      meta: { title: 'Home' },
      children: [
        {
          path: 'keys',
          name: 'keytab',
          meta: {
            menu: 'keys',
            breadcrumb: [
              { name: 'PGP Keys' },
            ],
            title: 'Upload public PGP Keys',
          },
          component: PublicKeyCreate,
        },
        {
          path: 'keys/generate',
          name: 'generateKeys',
          meta: {
            menu: 'keys',
            breadcrumb: [
              { name: 'PGP Keys', link: '../keys' },
              { name: 'Generate' },
            ],
            title: 'Generate PGP Keys',
          },
          component: GenerateKeys,
        },
        {
          path: 'integrations',
          name: 'integrationstab',
          meta: {
            menu: 'integrations',
            breadcrumb: [
              { name: 'Integrations' },
            ],
            title: 'Integrations',
          },
          component: Integrations,
        },
        {
          path: 'integrations/create',
          name: 'createIntegration',
          meta: {
            menu: 'integrations',
            breadcrumb: [
              { name: 'Integrations', link: '../integrations' },
              { name: 'Create' },
            ],
            title: 'Create Integration',
          },
          component: CreateIntegration,
        },
        {
          path: 'form',
          name: 'form',
          component: Form,
          meta: { label: 'Form Test', title: 'Form Test', subHeading: 'Tenant ' },
        },
        {
          path: 'configuration',
          name: 'configuration',
          component: Configuration,
          meta: { label: 'Configuration', title: 'Configuration', subHeading: 'Tenant ' },
        },
        {
          path: 'configuration/merchants',
          name: 'merchants',
          meta: {
            menu: 'merchants',
            breadcrumb: [
              { name: 'Merchants' },
            ],
            label: 'Merchants',
            title: 'Merchants',
            subHeading: 'Tenant ',
          },
          component: MerchantList,
        },
        {
          path: 'configuration/merchantcreate',
          name: 'createMerchants',
          meta: {
            menu: 'merchants',
            breadcrumb: [
              { name: 'Merchants', link: '../configuration/merchants' },
              { name: 'Create Merchant' },
            ],
            label: 'Create new merchant',
            title: 'Create merchant',
            subHeading: 'Tenant ',
          },
          component: GenericForm,
          props: merchantCreateSettings,
        },
        {
          path: 'configuration/merchants/:merchantId',
          name: 'merchantEdit',
          meta: {
            breadcrumb: [
              { name: 'Merchants', link: '../merchants' },
              { name: 'Edit Merchant' },
            ],
            menu: 'merchants',
            label: 'Edit merchant',
            title: 'Edit merchant',
            subHeading: 'Tenant ',
          },
          component: GenericForm,
          props: merchantEditSettings,
        },
        {
          path: 'paymentPlans',
          name: 'landing',
          component: Landing,
          meta: {
            menu: 'paymentplans',
            breadcrumb: [
              { name: 'Payment plans' },
            ],
            label: 'Payment plans',
            title: 'Payment plans',
            subHeading: 'Tenant ',
          },
        },
        {
          path: 'configuration/users',
          name: 'users',
          component: UserList,
          meta: {
            menu: 'users',
            breadcrumb: [
              { name: 'User List' },
            ],
            label: 'Users',
            title: 'Users',
          },
        },
        {
          path: 'configuration/userCreate',
          name: 'createUsers',
          meta: {
            menu: 'users',
            breadcrumb: [
              { name: 'Users List', link: '../configuration/users' },
              { name: 'Create User' },
            ],
            label: 'Create new user',
            title: 'Create user',
            subHeading: 'Tenant ',
          },
          component: GenericForm,
          props: userCreateSettings,
        },
        {
          path: 'configuration/users/:userId',
          name: 'userEdit',
          meta: {
            breadcrumb: [
              { name: 'Users List', link: '../users' },
              { name: 'Edit User' },
            ],
            menu: 'users',
            label: 'Edit user',
            title: 'Edit user',
            subHeading: 'Tenant ',
          },
          component: GenericForm,
          props: userEditSettings,
        },
        {
          path: 'paymentPlans/:paymentPlanId',
          component: PaymentPlanIndex,
          children: [{
            path: '',
            name: 'paymentPlanOverview',
            component: PaymentPlanOverview,
            meta: {
              menu: 'paymentplans',
              breadcrumb: [
                { name: 'Payment plans', link: '../' },
                { name: 'Overview' },
              ],
              title: 'Payment plan detail',
            },
          }, {
            path: 'history',
            name: 'paymentPlanHistory',
            component: PaymentPlanHistory,
            meta: {
              menu: 'paymentplans',
              breadcrumb: [
                { name: 'Payment plans', link: '../' },
                { name: 'History' },
              ],
              title: 'Payment plan history',
            },
          }, {
            path: 'adaptedRequest',
            name: 'adaptedRequest',
            component: AdaptedRequest,
            meta: {
              menu: 'paymentplans',
              breadcrumb: [
                { name: 'Payment plans', link: '../' },
                { name: 'Adapted Request' },
              ],
              title: 'Payment sent',
            },
          }],
        },
        {
          path: 'auditRequests',
          name: 'auditRequests',
          component: AuditRequestList,
          meta: {
            menu: 'auditRequests',
            breadcrumb: [
              { name: 'Audit Requests' },
            ],
            label: 'Audit Requests',
            title: 'Audit Requests',
          },
        },
        {
          path: 'auditRequests/:paymentPlanId',
          name: 'auditRequestOverview',
          component: AuditRequestOverview,
          meta: {
            menu: 'auditRequests',
            breadcrumb: [
              { name: 'Audit Requests', link: '../auditRequests' },
              { name: 'Overview' },
            ],
            title: 'Audit request detail',
          },
        },
      ],
    },
  ],
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (to.name === 'error' || from.path === '/' || to.path === '/callback' || auth.isAuthenticated()) {
    return next();
  }
  auth.login(to.params.tenant);
});

export default router;
