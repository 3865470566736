/* eslint max-len: ["error", { "code": 200 }] */
import axios from 'axios';
import Vue from 'vue';
import { omit } from 'lodash';
import store from '../store';

Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.dataService) {
      this.$dataService = options.dataService;
    } else if (options.parent && options.parent.$dataService) {
      this.$dataService = options.parent.$dataService;
    }
  },
});

export default function dataService(tenant) {
  const accessToken = localStorage.getItem('accessToken');
  axios.interceptors.response.use(undefined, err => new Promise((() => {
    if (err.response.status === 401) {
      if (tenant && tenant.length > 0) {
        this.$auth.renewTokens(tenant);
      } else {
        this.$router.push({ name: 'home' });
      }
    }
    if (tenant && this.$auth.isAuthenticated() && err.response.status === 403) {
      setTimeout(() => this.$buefy.dialog.alert({
        title: 'Information',
        message: 'You do not have access. Please contact your system administrator',
        confirmText: 'OK',
        type: 'is-info',
        hasIcon: true,
      }), 3000);
    } else if (store.state.roles.length === 0) {
      setTimeout(() => this.$buefy.dialog.alert({
        title: 'Information',
        message: 'You do not have access. Please contact your system administrator',
        confirmText: 'OK',
        type: 'is-info',
        hasIcon: true,
      }), 3000);
    }
    throw err;
  })));

  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  // Merchants
  const merchants = {
    merchantsList: () => axios.get(`/api/${tenant}/merchants`),
    merchantById: merchantId => axios.get(`/api/${tenant}/merchants/${merchantId}`),
  };

  // Users
  const users = {
    list: options => axios({
      method: 'GET',
      url: `/api/${tenant}/users`,
      params: options,
    }),
  };

  const publicKeys = {
    uploadKeys: data => axios.post(`/api/${tenant}/publicKeys`,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } }),
    getKeys: () => axios({
      method: 'GET',
      url: `/api/${tenant}/publicKeys`,
    }),
  };

  const paymentPlans = {
    get: paymentPlanId => axios({
      method: 'GET',
      url: `/api/${tenant}/paymentPlans/${paymentPlanId}`,
    }),
    history: paymentPlanId => axios({
      method: 'GET',
      url: `/api/${tenant}/paymentPlans/${paymentPlanId}/history`,
    }),
  };

  const cleanPaymentData = {
    list: options => axios({
      method: 'GET',
      url: `/api/${tenant}/cleanPaymentData`,
      params: options,
    }),
    get: paymentPlanId => axios({
      method: 'GET',
      url: `/api/${tenant}/cleanPaymentData/${paymentPlanId}`,
    }),
  };

  const auditRequests = {
    list: options => axios({
      method: 'GET',
      url: `/api/${tenant}/requestAudits`,
      params: options,
    }),
    get: paymentPlanId => axios({
      method: 'GET',
      url: `/api/${tenant}/requestAudits/${paymentPlanId}`,
    }),
  };

  const integrations = {
    list: () => axios({
      method: 'GET',
      url: `/api/${tenant}/applications`,
    }),
    create: data => axios.post(`/api/${tenant}/applications`, data),
    delete: clientId => axios.delete(`/api/${tenant}/applications/${clientId}`),
  };

  const merchantCreate = data => axios.post(`/api/${tenant}/merchants`, data);
  const merchantEdit = (data, id) => axios.put(`/api/${tenant}/merchants/${id}`, omit(data, ['krn']));
  const merchantById = merchantId => axios.get(`/api/${tenant}/merchants/${merchantId}`);
  const merchantTypes = () => axios.get('/api/paymentProviders');
  const userCreate = data => axios.post(`/api/${tenant}/users`, data);
  const getUserById = userId => axios.get(`/api/${tenant}/users/${userId}`);
  const editUser = (data, id) => axios.put(`/api/${tenant}/users/${id}`, data);
  const getRoles = () => axios.get(`/api/${tenant}/roles`);
  const getUserTenants = () => axios.get('/api/user/tenants');
  const getAdaptedRequest = paymentPlanId => axios.get(`/api/${tenant}/adaptedRequest/${paymentPlanId}`);

  return {
    integrations,
    merchants,
    users,
    getUserById,
    editUser,
    userCreate,
    getRoles,
    merchantById,
    merchantCreate,
    merchantEdit,
    merchantTypes,
    paymentPlans,
    cleanPaymentData,
    publicKeys,
    getUserTenants,
    getAdaptedRequest,
    auditRequests,
  };
}
